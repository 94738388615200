// Intentionally use the filename 'FullWidth.module.scss' to avoid the classname including the word 'ad' which causes ad blockers to remove it

@import "/styles/variables";

.container {
  overflow: hidden;

  margin: $space-30 0;
  padding: $space-30;

  background: $color-greyMinus3;
  border-radius: 32px;

  @include media("mobile", "tablet") {
    margin: $space-15 0;
    padding: $space-30 $space-20;
    border-radius: 20px;
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space-30;

  > *:not(:last-child) {
    margin-right: $space-8;
  }

  @include media("mobile", "tablet") {
    flex-direction: column;
    align-items: flex-start;

    > *:not(:last-child) {
      margin-right: 0;
    }
  }
}

.title {
  gap: $space-8;
  margin-bottom: $space-8;
  @include media("mobile", "tablet") {
    gap: $space-4;
    margin-bottom: $space-4;
  }
}

.logo {
  // align the logo with the rest of the text
  margin-top: 6px;

  @include media("mobile", "tablet") {
    // align the logo with the rest of the text
    margin-top: 3px;

    svg {
      width: auto;
      height: 14px;
    }
  }
}

.cta {
  svg {
    width: 10px;
    height: 10px;
    fill: $color-grey;
  }
  @include media("mobile", "tablet") {
    width: 100%;
    margin-top: $space-15;
  }
}

.grid {
  overflow: hidden;
  display: grid;
  grid-gap: $space-4;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin: 0;
  padding: 0;

  border-radius: 24px;

  @include media("mobile", "tablet") {
    grid-template-columns: 1fr 1fr;
    border-radius: 15px;
  }
}

.gridItem {
  display: block;
  width: 100%;

  @include media("mobile", "tablet") {
    &:nth-child(n + 5) {
      display: none;
    }
  }

  a {
    position: relative;

    overflow: hidden;
    display: block;

    width: 100%;
    padding-bottom: 110%;

    @include media("mobile", "tablet") {
      padding-bottom: 75%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .playIcon {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 50px;
      height: 50px;

      fill: white;
    }
  }
}
