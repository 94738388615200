@import "/styles/variables";

.overflowContainer {
  position: relative;
  overflow: hidden;
  display: block;
  height: 100%;
}

.mediumLink {
  cursor: inherit;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.widthContainer {
  @include media("mobile", "tablet") {
    min-height: 0 !important;
    max-height: 100% !important;
  }

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

.aspectRatioBox {
  position: relative;
  width: 100%;
  height: 0;
}

.iframe,
.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;
}

.image {
  // transform-origin: center center;
  transition: 0.1s transform ease;
}
