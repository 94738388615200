@import "/styles/variables";

$maxPages: 3; // The number of pages (including the initial page) that must be present before the sentinel reaches its maximum size/offset

@function lerp($from, $to) {
  $pageCount: var(--size, 1);
  $perPage: ($to - $from) / $maxPages;

  @return calc($from + $perPage * min($pageCount - 1, $maxPages));
}

.gridContainer {
  position: relative;
}

.gridWrapper {
  position: relative;
  width: 100%;
}

.mockRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: $space-20;
  margin-bottom: $space-20;
}

.loadMore {
  @include media("mobile", "tablet") {
    width: 100%;
  }
}

.loadSentinel {
  pointer-events: none;

  position: absolute;
  z-index: -100;
  bottom: lerp(70vh, 150vh);

  width: 100%;
  height: lerp(10vh, 75vh);

  &.overflowScroll {
    bottom: 100px;
  }
}
