@use "sass:color";

@import "/styles/variables";
@import "/shared/ui/Text/Text.module";

$border-radius: 12px;
$border-radius-tab: 30px;

$button-height: 50px;
$button-height-mobile: 40px;

$badge-text-padding: 4px;
$contrast-limit-border: 98;
$contrast-limit-text: 95;

/**
 * Util to help ensure that colours have a good contrast against a white background.
 *
 * Supports the special-case behavior of white buttons, which must have non-white border/text colors to remain accessible.
 */
@function contrast-color($main, $ideal, $fallback, $limit: $contrast-limit-text) {
  @return if(color.lightness($main) > $limit, $fallback, $ideal);
}

@mixin coloredButton($main-color, $hover-color, $disabled-color) {
  $text-color: contrast-color($main-color, $color-white, $color-greyscale-shadow);

  color: $text-color;
  background: $main-color;
  border-color: contrast-color($main-color, $main-color, $color-greyscale-400, $contrast-limit-border);

  &:is(a) > span {
    // Prevent a wrapping Text component from overriding LinkButton text colour
    color: $text-color;
  }

  &:not(.skipIconFillOverride) {
    svg {
      fill: $text-color;
    }
  }

  .badge {
    background: $main-color;
  }

  // Brand redesign doesn't define focus/active states. Using placeholder approach of blending normal and hover states.
  &:focus-visible,
  &:active {
    background: color.mix($main-color, $hover-color, 15%);

    .badge::after {
      background: color.mix($main-color, $color-black, 85%);
    }
  }

  &:hover,
  &.hover {
    background: $hover-color;
    border-color: contrast-color($main-color, $hover-color, $color-greyscale-800, $contrast-limit-border);

    .badge::after {
      background: $hover-color;
    }
  }

  // Can suppress the visual hint that a button is disabled by adding `aria-disabled={false}` to a disabled button
  &.disabled:not([aria-disabled="false"]) {
    &,
    &:focus,
    &:active,
    &:hover,
    &.hover {
      color: contrast-color($disabled-color, $color-white, $color-greyscale-600);
      background: $disabled-color;
      border-color: contrast-color($main-color, $disabled-color, $color-greyscale-400);

      &:not(.skipIconFillOverride) {
        svg {
          fill: contrast-color($disabled-color, $color-white, $color-greyscale-600);
        }
      }
    }
  }
}

@mixin colorOverride($color) {
  color: $color;

  &:not(.skipIconFillOverride) {
    svg {
      fill: $color;
    }
  }

  &:hover,
  &.hover {
    color: $color;

    &:not(.skipIconFillOverride) {
      svg {
        fill: $color;
      }
    }
  }
}

@mixin hideTextFor($media, $buttonWidth) {
  @include media($media) {
    width: $buttonWidth;

    .text {
      display: none;
    }

    svg:first-child:not(:last-child) {
      margin-right: 0;
    }

    svg:last-child:not(:first-child) {
      margin-left: 0;
    }
  }
}

@layer utilities {
  .button {
    @extend %label-default;

    position: relative;
    z-index: 0;

    box-sizing: border-box;
    width: max-content;
    max-width: 100%;
    height: $button-height;

    line-height: 1;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: top;

    background: none;
    border: 1px solid;
    border-radius: $border-radius;
    outline: none;

    transition:
      0.1s background-color,
      border ease-in-out,
      0.1s transform,
      0.005s box-shadow,
      0.25s border-color;

    &,
    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    & > span {
      width: 100%;

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.responsiveButton {
      @include media("mobile") {
        height: $button-height-mobile;
        font-size: 16px;

        svg {
          width: 20px;
          height: 20px;
        }

        &.iconSolo {
          width: $button-height-mobile;
        }

        &.imgixIcon .imgixIconImage {
          $mobile-size: 27px;

          width: $mobile-size;
          min-width: $mobile-size;
          height: $mobile-size;
          min-height: $mobile-size;
        }
      }
    }

    svg {
      flex: none;
      width: 22px;
      height: 22px;
      transition:
        0.1s fill,
        0.2s transform ease-in-out;
    }

    svg:first-child:not(:last-child) {
      margin-right: $space-8;
    }

    svg:last-child:not(:first-child) {
      margin-left: $space-8;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .rotateIcon {
    svg:first-child:not(:last-child) {
      transform: rotate(-180deg);
    }

    svg:last-child:not(:first-child) {
      transform: rotate(180deg);
    }
  }

  .iconSolo {
    width: $button-height;

    .rotateIcon {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .noText {
    .number {
      margin-left: 0;
    }
  }

  .imgixIcon {
    $size: 34px;

    .imgixIconImage {
      position: relative;

      overflow: hidden;

      width: $size;
      min-width: $size;
      height: $size;
      min-height: $size;
      margin-right: $space-8;

      background: $color-greyMinus1;
      border-radius: 50%;

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .shadowEffect {
    &:hover,
    &.hover {
      box-shadow: 0 3px 5px color.change($color-black, $alpha: 0.05);
    }
  }

  // Modifiers

  .number {
    display: inline-block;
    margin-left: $space-8;
  }

  .badge {
    position: absolute;
    top: 0;
    left: $space-30 - $badge-text-padding;
    transform: translateY(-50%);

    padding: 0 $badge-text-padding;

    &::after {
      content: "";

      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;

      width: 100%;
      height: calc(50% + 1px); // 1px to cover the button border

      transition: 0.1s background;
    }
  }

  .block {
    display: flex;
  }

  .button svg,
  .text,
  .number,
  .block {
    z-index: 1;
    line-height: 1.3;
  }

  .fullWidth {
    width: 100%;
  }

  .hideTextMobile {
    @include hideTextFor("mobile", $button-height-mobile);
  }

  .hideTextTablet {
    @include hideTextFor("tablet", $button-height);
  }

  .hideTextDesktop {
    @include hideTextFor("desktop", $button-height);
  }

  .hideTextOversized {
    @include hideTextFor("oversized", $button-height);
  }

  // Colors
  .color-alien {
    @include coloredButton($color-alien, $color-alien-dark, $color-alien-lighter);
  }

  .color-jellyfish {
    @include coloredButton($color-jellyfish, $color-jellyfish-dark, $color-jellyfish-lighter);
  }

  .color-papaya {
    @include coloredButton($color-papaya, $color-papaya-dark, $color-papaya-lighter);
  }

  .color-strawberry {
    @include coloredButton($color-strawberry, $color-strawberry-dark, $color-strawberry-lighter);
  }

  .color-sunshine {
    @include coloredButton($color-sunshine, $color-sunshine-dark, $color-sunshine-lighter);
  }

  .color-white {
    @include coloredButton($color-greyscale-white, $color-greyscale-200, $color-greyscale-white);
  }

  .color-grey {
    @include coloredButton($color-greyscale-300, $color-greyscale-500, $color-greyscale-200);
  }

  .color-shadow {
    @include coloredButton($color-greyscale-shadow, $color-greyscale-900, $color-greyscale-600);
  }

  .color-transparent {
    @include coloredButton(transparent, color.change($color-black, $alpha: 0.4), transparent);

    border: none;
  }

  // Override the button font color - must come after the theme classes

  .color-override-white {
    @include colorOverride($color-white);
  }

  .color-override-shadow {
    @include colorOverride($color-shadow);
  }

  .color-override-grey {
    @include colorOverride($color-grey);
  }

  .color-override-alien {
    @include colorOverride($color-alien);
  }

  .color-override-jellyfish {
    @include colorOverride($color-jellyfish);
  }

  .color-override-papaya {
    @include colorOverride($color-papaya);
  }

  .color-override-strawberry {
    @include colorOverride($color-strawberry);
  }

  .color-override-sunshine {
    @include colorOverride($color-sunshine);
  }

  // Override the button font weight - must come after the theme classes

  .weight-override-bold {
    font-weight: $font-weight-bold;
  }

  .weight-override-semibold {
    font-weight: $font-weight-semibold;
  }

  .weight-override-medium {
    font-weight: $font-weight-medium;
  }

  .disabled {
    cursor: not-allowed;

    // pointer-events: none;
  }

  .social {
    background: none;
    border: none;
    border-radius: 50%;

    svg {
      width: 40px;
      height: 40px;
      fill: $color-greyscale-900;
    }

    &:hover {
      background: none;

      svg {
        fill: $color-jellyfish;
      }
    }
  }

  .noBorder {
    border: none;
  }

  .circle {
    border-radius: 50%;
  }

  .rounded {
    border-radius: 1000px;
  }

  .allowTextWrap {
    height: auto;
    padding-top: $space-8;
    padding-bottom: $space-8;
    white-space: normal;
  }

  .accentOnHover:hover {
    color: $color-green;
    background: color.mix($color-white, $color-green, 95%);
    border: 2px solid $color-green;

    svg {
      fill: $color-green;
    }
  }
}
