@use "sass:color";
@import "/styles/variables";

.card:not(:hover) .hideWhenNotHovered {
  @include media("desktop", "oversized") {
    display: none;
  }
}

.card {
  position: relative;

  contain: content;
  display: flex;
  flex-direction: column;

  border-radius: 12px;

  // Any elements (anywhere in tile) tagged "hoveronly" are visible only on mouseover
  &:not(:hover) *[data-hoveronly="true"] {
    visibility: hidden;

    // On mobile, always show badges and download btn
    @include media("mobile", "tablet") {
      & {
        visibility: visible;
      }
    }
  }

  > div {
    pointer-events: none;

    position: absolute;

    display: flex;
    gap: 4px;

    width: 100%;
    padding: 12px;

    @include media("mobile", "tablet") {
      // Spacing on mobile is a little inconsistent
      gap: 8px;
      padding: 8px 12px 12px 10px;
    }

    > * {
      pointer-events: all;
    }

    &:first-of-type {
      top: 0;
    }

    &:last-of-type {
      bottom: 0;
    }
  }

  @media (hover: hover) {
    .overlayBottom {
      margin-bottom: -12px;
      opacity: 0;
    }

    &:hover {
      .overlayBottom {
        margin-bottom: 0;
        opacity: 1;
      }
    }
  }
}

.button {
  pointer-events: all;
  width: 45px;
  height: 45px;
  margin-left: auto;

  svg {
    width: 24px;
    height: 24px;
  }

  &[data-active="true"] svg {
    animation-name: pulse-in;
    animation-duration: 0.5s;
  }

  @include media("mobile", "tablet") {
    display: none;
  }
}

.content {
  position: absolute;
  inset: 0;

  img {
    width: 100%;
    object-fit: cover;
  }

  > * {
    position: absolute;
    inset: 0;
  }
}

.overlay {
  pointer-events: none;
  opacity: 0;
  background: linear-gradient(180deg, rgb(0 0 0 / 10%), rgb(0 0 0 / 0%) 20%, rgb(0 0 0 / 0%) 80%, rgb(0 0 0 / 30%));
  transition: opacity 0.1s linear;

  .card:focus &,
  .card:hover & {
    opacity: 1;
  }

  @include media("mobile", "tablet") {
    opacity: 1;
  }
}

@keyframes pulse-in {
  0% {
    transform: scale(1.35);
  }

  100% {
    transform: none;
  }
}

.overlayBottom {
  transition: 0.25s;
}
