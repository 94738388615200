.pagination {
  position: absolute;
  top: -999999px;
  left: -999999px;
}

.noJs {
  position: relative;
  top: 0;
  left: 0;
}
