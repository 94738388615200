mux-player.player {
  --seek-backward-button: none;
  --seek-forward-button: none;
  --pip-button: none;

  &:not(.withAudio) {
    --volume-range: none;
    --mute-button: none;
  }
}
