@import "/styles/variables";

$spaces: 4, 8, 12, 15, 20, 24, 30, 32, 50, 80;

.grid {
  position: relative;
  display: none;
  width: 100%;

  &.alwaysVisible {
    display: flex;
  }
}

@each $breakpoint in $media-ordered-sizes {
  @include media($breakpoint) {
    .grid-#{$breakpoint} {
      display: flex;
    }
  }
}

@mixin columnGap($gap) {
  .column:not(:last-child) {
    margin-right: #{$gap}px;
  }

  > .grid > .column > .item:not(:last-child) {
    margin-bottom: #{$gap}px;
  }
}

@each $space in $spaces {
  .grid-spacing-#{$space} {
    @include columnGap($space);
  }
}

.grid-spacing-responsive {
  // Keep in-sync with responsiveColumnGap in convertColumnSizingToSizes.tsx
  @include columnGap(24);

  @include media("mobile", "tablet") {
    @include columnGap(12);
  }
}

.column {
  display: block;
  flex: 1 1 0;
  width: 100%;
  min-width: 0;

  &:last-child {
    margin-right: 0;
  }
}
