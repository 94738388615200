@import "/styles/variables";

$imageSize: 120px;
$imageSizeMobile: 100px;
$iconContainerSize: 32px;
$iconContainerSizeMobile: 26px;
$iconSize: 18px;
$iconSizeMobile: 14px;

:export {
  /* stylelint-disable-next-line property-no-unknown */
  imageSize: $imageSize;
}

.imageContainer {
  @include media("mobile") {
    width: $imageSizeMobile;
    height: $imageSizeMobile;
  }

  position: relative;
  width: $imageSize;
  height: $imageSize;
}

.image,
.imageLoadingBox {
  border-radius: 12px;
}

.image {
  width: 100%;
  height: 100%;
}

.imageLoadingBox {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
}

.icon {
  @include media("mobile") {
    width: $iconContainerSizeMobile;
    height: $iconContainerSizeMobile;
    margin-top: -$iconContainerSizeMobile / 2;
  }

  z-index: 1;

  width: $iconContainerSize;
  height: $iconContainerSize;
  margin-top: -$iconContainerSize / 2;

  border-radius: 100%;
  outline: 2px solid white;

  svg {
    @include media("mobile") {
      width: $iconSizeMobile;
      height: $iconSizeMobile;
    }

    width: $iconSize;
    height: $iconSize;
  }

  &.red {
    background: $color-red;
  }

  &.green {
    background: $color-green;
  }

  &.purple {
    background: $color-purple;
  }

  &.yellow {
    background: $color-yellow;
  }

  &.noir {
    background: $color-noir;
  }
}
