@use "sass:color";

@import "/styles/variables";
@import "/shared/ui/Button/Button.module";

.downloadButton {
  @include media("mobile", "tablet") {
    padding: 0;
    background: none;
  }

  pointer-events: all;

  width: 40px;
  height: 40px;
  margin-left: auto;

  border: 0;
  border-radius: 10px;

  svg {
    width: 16px;
    height: 16px;
    fill: $color-white;

    @include media("mobile", "tablet") {
      // Gets larger on mobile, since at these screen sizes the button is *just* the icon
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    background: color.change($color-black, $alpha: 0.4);
  }

  &.showOnlyIconOnDesktop {
    svg:first-child:not(:last-child) {
      margin-right: 0;
    }

    .downloadButtonText {
      display: none;
    }

    @include media("desktop", "oversized") {
      background: transparent;
    }
  }

  &.fullButtonOnDesktop:not(.showOnlyIconOnDesktop) {
    @include media("mobile", "tablet") {
      svg:first-child:not(:last-child) {
        margin-right: 0;
      }
    }

    @include media("desktop", "oversized") {
      @include coloredButton($color-alien, $color-alien-dark, $color-alien-lighter);

      width: auto;
      height: $button-height;
    }

    .downloadButtonText {
      @include media("desktop", "oversized") {
        display: inline-block;
      }

      display: none;
    }
  }
}
