.root {
  position: relative;

  overflow: hidden;
  display: block;

  aspect-ratio: var(--aspectRatio);

  border-radius: 12px;

  img {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;
  }
}
