@import "/styles/variables";

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
}

.spinner {
  div {
    position: absolute;
    bottom: 10px;
    left: -40px;
    transform: translateZ(0);

    width: 20px;
    height: 20px;

    background-color: $color-grey;
    border-radius: 50%;

    animation: move 2s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);

    &:nth-child(2) {
      animation-delay: 150ms;
    }

    &:nth-child(3) {
      animation-delay: 300ms;
    }

    &:nth-child(4) {
      animation-delay: 450ms;
    }
  }
}

@keyframes move {
  0% {
    left: 0;
  }

  75% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}
