// Intentionally use the filename 'Inline.module.scss' to avoid the classname including the word 'ad' which causes ad blockers to remove it

@import "/styles/variables";

.container {
  position: relative;

  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;

  width: 100%;
  height: 100%;
}

.gridContainer {
  position: relative;

  &:not(.isRowGrid) {
    padding-bottom: 80%;
  }
}

.title {
  @include media("mobile", "tablet") {
    flex-direction: column;
    align-items: flex-start;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.grid {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
  display: grid;
  grid-gap: $space-4;
  grid-template-columns: 1fr 1fr;

  border-radius: 20px;
}

.gridItem {
  position: relative;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .playIcon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    fill: white;
  }
}

.button {
  padding: $space-8;
}
